const locationsList = [
  {
      location: 'Fresno, CA Corporate Office',
      address: '2825 S. Elm Ave. #101', 
      zip: 'Fresno, CA 93706',
      phone: '(559) 233-6684',
      fax: '(559) 233-4206',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: 'Closed',
  },
  {
      location: 'Fresno, CA Store',
      address: '245 M St.', 
      zip: 'Fresno, CA 93721',
      phone: '(559) 233-9353',
      fax: '(559) 233-4581',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 12:00 pm',
  },
  {
      location: 'Visalia, CA Store',
      address: '2239 E. Main St.',
      zip: 'Visalia, CA 93292',
      phone: '(559) 733-2335',
      fax: '(559) 733-9614',
      hoursMF: '7:30 am to 5:00 pm',
      hoursSAT: '8:00 am to 12:00 pm',
  },
  {
      location: 'Porterville, CA Store',
      address: '1204 W. Olive Ave.',
      zip: 'Porterville, CA 93257',
      phone: '(559) 782-1025',
      fax: '(559) 782-3246',
      hoursMF: '7:30 am to 5:00 pm',
      hoursSAT: '8:00 am to 12:00 pm',
  },
  {
      location: 'Clovis, CA Store',
      address: '2742 Clovis Ave.',
      zip: 'Clovis, CA 93612',
      phone: '(559) 292-1234',
      fax: '(559) 292-1261',
      hoursMF: '7:30 am to 5:00 pm',
      hoursSAT: '8:00 am to 5:00 pm',
  },
  {
      location: 'Madera, CA Store',
      address: '311 S. Pine Ave.',
      zip: 'Madera, CA 93637',
      phone: '(559) 718-2072',
      fax: '(559) 718-2076',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 12:00 pm',
  },
  {
      location: 'Citrus Heights, CA Store',
      address: '6005 Auburn Blvd. #100',
      zip: 'Citrus Heights, CA 95621',
      phone: '(916) 560-2006',
      fax: '(916) 560-2010',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 5:00 pm',
  },
  {
      location: 'Bakersfield, CA Store',
      address: '5150 Rosedale Hwy.',
      zip: 'Bakersfield, CA 93308',
      phone: '(661) 322-9353',
      fax: '(661) 634-9001',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 5:00 pm',
  },
  {
      location: 'Sacramento, CA Store',
      address: '4218 Power Inn Rd.',
      zip: 'Sacramento, CA 95826',
      phone: '(916) 456-2478',
      fax: '(916) 737-7426',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 5:00 pm',
  },
  {
      location: 'Modesto, CA Store',
      address: '1501 Coldwell Ave.',
      zip: 'Modesto, CA 95350',
      phone: '(209) 491-2780',
      fax: '(209) 491-2888',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 12:00 pm',
  },
  {
      location: 'Stockton, CA Store',
      address: '1444 E. Mariposa Rd.',
      zip: 'Stockton, CA 95205',
      phone: '(209) 463-9353',
      fax: '(209) 463-9357',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 5:00 pm',
  },
  {
      location: 'Turlock, CA Store',
      address: '1105 Lander Ave.',
      zip: 'Turlock, CA 95380',
      phone: '(209) 216-5592',
      fax: '(209) 216-5593',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 12:00 pm',
  },
  {
      location: 'Salinas, CA Store',
      address: '1040 Abbott St.',
      zip: 'Salinas, CA 93901',
      phone: '(831) 783-1806',
      fax: '(831) 783-1828',
      hoursMF: '7:00 am to 5:00 pm',
      hoursSAT: '8:00 am to 5:00 pm',
  },
  {
      location: 'Fowler, CA Gas Plant',
      address: '7835 E. Manning Ave.',
      zip: 'Fowler, CA 93625',
      phone: '(559) 834-1613',
      fax: '(831) 834-4747',
      hoursMF: '8:00 am to 5:00 pm',
      hoursSAT: 'Closed',
  },
]

export default locationsList
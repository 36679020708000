import { Grid } from '@mui/material';
import Hero from '../../components/Hero';
import ContactForm from '../../components/ContactForm';
import Locations from './Locations';
import Map from './Map';

function Contact() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Hero title="Contact Us" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Map />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Locations />
      </Grid>
      <Grid item xs={12}>
        <ContactForm />
      </Grid>
    </Grid>
  )
}

export default Contact;
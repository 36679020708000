import { Box, Button, Container, Divider, Grid, Paper, Typography } from "@mui/material";

const Gases = [
  { name: 'Beverage CO2' },
  { name: 'Beverage Nitrogen' },
  { name: 'Food Grade Oxygen' }
];

function GasesList() {
  return (
    <Container sx={{ padding: "50px" }}>
      <Grid container spacing={2}>
        {Gases.map((gas, i) => (
          <Grid item xs={12} sm={4} key={i}>
            <Paper square elevation={3} sx={{ height: "100%" }}>
              <Box sx={{ padding: "50px" }}>
                <Typography variant="h5" color="primary">{gas.name}</Typography>
                <Divider sx={{ margin: "10px 0", borderColor: "secondary.main" }} />
                <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam malesuada feugiat ex sit amet interdum.</Typography>
                <Button variant="contained" sx={{ marginTop: "10px" }}>Learn More</Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default GasesList;
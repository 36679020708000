import * as React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Collapse, Grid, Typography } from '@mui/material';

function EquipmentCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ borderRadius: "0 0 4px 4px" }}>
        <CardActionArea
          onClick={handleExpandClick}
        >
          <Grid container>
            <Grid item xs={6}>
              <CardMedia 
                component="img"
                image={props.img}
                alt={props.name}
                sx={{ height: "200px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <CardContent>
                <Typography variant="h5">{props.name}</Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12}>
                
            </Grid>
          </Grid>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography>{props.desc}</Typography>
            </CardContent>
          </Collapse>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default EquipmentCard;
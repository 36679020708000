import { Grid } from '@mui/material';
import Hero from '../../components/Hero';
import DeliverySteps from './DeliverySteps';

function Delivery() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Hero title="We Deliver" />
      </Grid>
      <Grid item xs={12}>
        <DeliverySteps />
      </Grid>
    </Grid>
  )
}

export default Delivery;
import { Grid } from '@mui/material';
import Hero from '../../components/Hero';
import GasesList from './GasesList';
import GasTestimonials from './GasTestimonials';

function Gases() {
  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <Hero title="Our Gases" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <GasesList />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <GasTestimonials />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Gases;
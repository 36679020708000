import { Box, Container, Grid, Typography } from '@mui/material';
import filler from '../../assets/imgs/svgs/industries/brewers.svg';

const Steps = [
  {
    icon: filler,
    title: 'Step One',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  {
    icon: filler,
    title: 'Step Two',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  {
    icon: filler,
    title: 'Step Three',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  {
    icon: filler,
    title: 'Step Four',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  }
]

function DeliverySteps() {
  return (
    <Container>
      <Box sx={{ padding: "25px 0" }}>
        {Steps.map((step, i) => (
          <Grid container spacing={2} key={i} sx={{ padding: "25px 0" }}>
            <Grid item xs={12} sm={2} display="flex" justifyContent="center">
              <img 
                src={filler}
                alt="test"
                height="100px"
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant="h4" color="primary.main">{step.title}</Typography>
              <Typography>{step.desc}</Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Container>
  )
}

export default DeliverySteps;
import { Box, Container, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";

const Testimonials = [
  { 
    name: "Random Person/Company 1",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus consequat tellus et ipsum faucibus varius. Mauris posuere urna risus, luctus interdum augue fringilla tempor. Duis commodo mattis felis condimentum consequat. Etiam accumsan felis nec felis dictum, et congue risus vulputate. Mauris gravida purus neque, id sagittis justo facilisis quis. Pellentesque ac turpis diam. Curabitur gravida, urna a laoreet malesuada, eros dui facilisis odio, eget ultrices tellus nisi ac elit. Donec sit amet velit commodo, malesuada elit sit amet, venenatis magna. Pellentesque at lacus nunc." 
  },
  { 
    name: "Random Person/Company 2",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus consequat tellus et ipsum faucibus varius. Mauris posuere urna risus, luctus interdum augue fringilla tempor. Duis commodo mattis felis condimentum consequat. Etiam accumsan felis nec felis dictum, et congue risus vulputate. Mauris gravida purus neque, id sagittis justo facilisis quis. Pellentesque ac turpis diam. Curabitur gravida, urna a laoreet malesuada, eros dui facilisis odio, eget ultrices tellus nisi ac elit. Donec sit amet velit commodo, malesuada elit sit amet, venenatis magna. Pellentesque at lacus nunc." 
  },
  { 
    name: "Random Person/Company 3",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus consequat tellus et ipsum faucibus varius. Mauris posuere urna risus, luctus interdum augue fringilla tempor. Duis commodo mattis felis condimentum consequat. Etiam accumsan felis nec felis dictum, et congue risus vulputate. Mauris gravida purus neque, id sagittis justo facilisis quis. Pellentesque ac turpis diam. Curabitur gravida, urna a laoreet malesuada, eros dui facilisis odio, eget ultrices tellus nisi ac elit. Donec sit amet velit commodo, malesuada elit sit amet, venenatis magna. Pellentesque at lacus nunc." 
  },
];

function GasTestimonials() {
  return (
    <Box sx={{ backgroundColor: "secondary.main", color: "white", textAlign: "center", padding: "50px 0" }}>
      <Container>
        <Typography variant="h2">Testimonials</Typography>
        <Carousel 
          animation="slide" 
          navButtonsAlwaysInvisible
          indicatorIconButtonProps={{ 
            style: {
              color: "white"
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#1B75BB"
            }
          }}
          indicatorContainerProps={{
            style: {
              marginTop: "0"
            }
          }}
        >
          {Testimonials.map((testimonial, i) => (
            <Box key={i} sx={{ padding: "50px 0" }}>
              <Typography variant="h5">{testimonial.name}</Typography>
              <Typography>{testimonial.desc}</Typography>
            </Box>
          ))}
        </Carousel>
      </Container>
    </Box>
  )
}

export default GasTestimonials;
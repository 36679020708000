import { Grid } from "@mui/material";
import Hero from "../../components/Hero";
import EquipmentList from "./EquipmentList";

function Equipment() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Hero title="Our Equipment" />
      </Grid>
      <Grid item xs={12}>
        <EquipmentList />
      </Grid>
    </Grid>
  )
}

export default Equipment;
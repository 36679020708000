import Home from '../views/Home';
import Gases from '../views/Gases';
import Equipment from '../views/Equipment';
import Delivery from '../views/Delivery';
import Contact from '../views/Contact';
import About from '../views/About';

const MainRoutes = [
  {
    path: '/',
    element: <Home />,
    name: 'Home'
  },
  {
    path: '/gases',
    element: <Gases />,
    name: 'Gases'
  },
  {
    path: '/equipment',
    element: <Equipment />,
    name: 'Equipment'
  },
  {
    path: '/delivery',
    element: <Delivery />,
    name: 'Delivery'
  },
  {
    path: '/about',
    element: <About />,
    name: 'About'
  },
  {
    path: '/contact',
    element: <Contact />,
    name: 'Contact'
  }
]

export default MainRoutes;
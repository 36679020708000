import { Box, Container, Grid } from '@mui/material';
import EquipmentCard from './EquipmentCard';
import Regulator from '../../assets/imgs/equipment/regulator.jpg';

const Equipment = [
  {
    img: Regulator,
    name: 'Regulators',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel dapibus mi, vel faucibus felis. Integer scelerisque tellus non nulla placerat, vitae ultrices lacus faucibus. In fringilla molestie dui in interdum. Nunc posuere aliquet tortor ac blandit. Maecenas a sem ligula. Maecenas eget semper nunc, vitae convallis enim.'
  },
  {
    img: Regulator,
    name: 'Flowmeters',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel dapibus mi, vel faucibus felis. Integer scelerisque tellus non nulla placerat, vitae ultrices lacus faucibus. In fringilla molestie dui in interdum. Nunc posuere aliquet tortor ac blandit. Maecenas a sem ligula. Maecenas eget semper nunc, vitae convallis enim.'
  },
  {
    img: Regulator,
    name: 'Switchovers',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel dapibus mi, vel faucibus felis. Integer scelerisque tellus non nulla placerat, vitae ultrices lacus faucibus. In fringilla molestie dui in interdum. Nunc posuere aliquet tortor ac blandit. Maecenas a sem ligula. Maecenas eget semper nunc, vitae convallis enim.'
  },
  {
    img: Regulator,
    name: 'Dewars',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel dapibus mi, vel faucibus felis. Integer scelerisque tellus non nulla placerat, vitae ultrices lacus faucibus. In fringilla molestie dui in interdum. Nunc posuere aliquet tortor ac blandit. Maecenas a sem ligula. Maecenas eget semper nunc, vitae convallis enim.'
  },
  {
    img: Regulator,
    name: 'Micro Bulk',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel dapibus mi, vel faucibus felis. Integer scelerisque tellus non nulla placerat, vitae ultrices lacus faucibus. In fringilla molestie dui in interdum. Nunc posuere aliquet tortor ac blandit. Maecenas a sem ligula. Maecenas eget semper nunc, vitae convallis enim.'
  },
  {
    img: Regulator,
    name: 'Bulk Tank',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel dapibus mi, vel faucibus felis. Integer scelerisque tellus non nulla placerat, vitae ultrices lacus faucibus. In fringilla molestie dui in interdum. Nunc posuere aliquet tortor ac blandit. Maecenas a sem ligula. Maecenas eget semper nunc, vitae convallis enim.'
  },
  {
    img: Regulator,
    name: 'Pulsa Telemetry',
    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel dapibus mi, vel faucibus felis. Integer scelerisque tellus non nulla placerat, vitae ultrices lacus faucibus. In fringilla molestie dui in interdum. Nunc posuere aliquet tortor ac blandit. Maecenas a sem ligula. Maecenas eget semper nunc, vitae convallis enim.'
  },
];

function EquipmentList() {
  return (
    <Box sx={{ padding: "50px 0" }}>
      <Container>
        <Grid container spacing={2}>
          {Equipment.map((equip, i) => (
            <EquipmentCard 
              key={i}
              img={equip.img}
              name={equip.name}
              desc={equip.desc}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default EquipmentList;
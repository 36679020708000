import { Grid } from '@mui/material';
import Hero from './Hero';
import WeServe from './WeServe';
import Highlights from './Highlights';
import WhyUs from './WhyUs';
import ContactForm from '../../components/ContactForm';

function Home() {
  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <Hero />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <WeServe />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Highlights />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <WhyUs />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <ContactForm />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Home;